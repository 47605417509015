export function disabledForPermsTest(event) {
	var textContent = $.__('This action would normally be available to this user.  It has been disabled while you preview.');
	var title = $.__('Disabled for Previewing');

	window.BambooHR.Modal.setState({
		content: textContent,
		isOpen: true,
		primaryAction() {
			window.BambooHR.Modal.setState({isOpen: false});
		},
		title
	});

	if (typeof event !== 'undefined') {
		event.stopImmediatePropagation();
		event.preventDefault();
	}
	return false;
}
