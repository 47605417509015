/* eslint-disable no-negated-condition */
import {
	Flex,
} from '@bamboohr/fabric';
/* @startCleanup encore */
import { ifFeature } from '@utils/feature';
/* @endCleanup encore */
import {
	useState,
	useEffect,
} from 'react';
import c from 'classnames';

import { isEnabled } from 'FeatureToggle.util';

import { showStateTaxWHMigrationMessage, isNewStateTaxWithholdingForm } from './withholding.domain';
import { FedWithholding } from '../fed-withholding.react';
import { FedWithholding2020 } from '../fed-withholding-2020.react';
import { IowaStateWithholding } from '../iowa-state-withholding.react';
import { StateWithholding } from '../state-withholding.react';
import { StateUnemployment } from '../state-unemployment.react';
import { WithholdingBlankState } from '../withholding-blank-state.react';
import { validateFederalTaxData, validateStateTaxData } from '../../utils';

import './withholding.styl';

export function Withholding(props) {
	const {
		data,
		hasBlankStateHeader,
		headerHeadlineSize,
		newHirePacket,
		show,
		fedPerms,
		statePerms,
		onStateLocationChange,
		onStateUnemploymentLocationChange,
		isAdminUser,
		isGreenhouse = false,
		isPayrollAdminUser = false,
		canAccessStateUiFields = false,
		renderFederal = true,
		renderState = true,
		renderStateUnemployment = true,
		sectionsFlexGap = 4,
		showWithholdingInformation,
		stateSectionNote = '',
		stateUnemploymentSectionNote = '',
		showStateLocationRequiredAsterisk = false,
		showStateUnemploymentLocationRequiredAsterisk = false,
		usesSectionComponent,
	} = props;

	const {
		federal,
		federal2020,
		has2020W4,
		is2020Release,
		state,
		stateUnemployment,
	} = data;

	const isNewIowaFormAccessEnabled = isGreenhouse || isEnabled('PAY_INFO_TAB_IOWA_STATE_WITHHOLDING_VIEW_ACCESS');
	const [temporary2020, setTemporary2020] = useState(false);
	const [newIowaStateForm, setNewIowaStateForm] = useState(isNewIowaFormAccessEnabled && state ? isNewStateTaxWithholdingForm(state.locations, state.locations.selected) : false);
	const [selectedStateLocation, setSelectedStateLocation] = useState(isNewIowaFormAccessEnabled && state && state.locations.selected ? state.locations.selected : null);
	const [showIowaStateMessage, setShowIowaStateMessage] = useState(isNewIowaFormAccessEnabled && state ? showStateTaxWHMigrationMessage(state.locations, selectedStateLocation) : false);
	const callbackReset2020Form = () => setTemporary2020(false);
	const callbackResetIowaStateForm = () => {
		if (isNewIowaFormAccessEnabled) {
			setSelectedStateLocation(state.locations.selected ?? null);
			if (isNewStateTaxWithholdingForm(state.locations, state.locations.selected) === false) {
				setNewIowaStateForm(false);
			}
		}
	};

	// If they cancel a 2020 or newIowa form edit, revert back to pre-2020 Federal Withholding or pre-new iowa form fields.
	useEffect(() => {
		const payInfoTabFooter = document.querySelector('button[data-action="SiteFooterAction:cancel"]');

		if (payInfoTabFooter) {
			payInfoTabFooter.addEventListener('click', callbackReset2020Form);
			payInfoTabFooter.addEventListener('click', callbackResetIowaStateForm);
		}

		return () => {
			// Clean up: remove the listener when the React component is unmounted
			if (payInfoTabFooter) {
				payInfoTabFooter.removeEventListener('click', callbackReset2020Form);
				payInfoTabFooter.removeEventListener('click', callbackResetIowaStateForm);
			}
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		if (isNewIowaFormAccessEnabled && selectedStateLocation) {
			setShowIowaStateMessage(showStateTaxWHMigrationMessage(state.locations, selectedStateLocation));
			setNewIowaStateForm(isNewStateTaxWithholdingForm(state.locations, selectedStateLocation));
		} else {
			setShowIowaStateMessage(false);
			setNewIowaStateForm(false);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [selectedStateLocation]);

	let showFederalWithholding = null;
	let showStateWithholding = null;
	let showStateUi = null;

	if (showWithholdingInformation) {
		({
			showFederalWithholding,
			showStateWithholding,
			showStateUi,
		} = showWithholdingInformation);
	}

	const showFederal2020 = is2020Release && (has2020W4 || temporary2020);

	/* @startCleanup encore */
	const federalClasses = c(
		'Federal',
		{ 'Federal--2020FedEx': showFederal2020 },
		{ 'Federal--hidden': showFederalWithholding === false },
	);
	/* @endCleanup encore */
	const isNhpPreview = newHirePacket && window.previewUser;
	const isEmployerView = !window.SESSION_USER?.employeeId || (window.SESSION_USER?.employeeId && (window.SESSION_USER?.employeeId !== window.currentlyEditingEmployeeId))

	const stateAccessLevel = window.stateTaxDeductionsPermissions;
	const unemploymentAccessLevel = window.unemploymentInsurancePermissions;
	// Access Levels: 0 => No Access; 1 or 2 => View; 3 => Edit;
	const stateEditAccessLevel = stateAccessLevel === 3;
	const stateViewOnlyAccessLevel = stateAccessLevel === 2 || stateAccessLevel === 1;
	const unemploymentEditAccessLevel = unemploymentAccessLevel === 3;
	const unemploymentViewOnlyAccessLevel = unemploymentAccessLevel === 2 || unemploymentAccessLevel === 1;

	if (
		(statePerms !== 0 && !validateStateTaxData(data)) ||
		(fedPerms !== 0 && !validateFederalTaxData(data))
	) {
		return (
			<WithholdingBlankState
				hasHeader={hasBlankStateHeader}
				headerHeadlineSize={headerHeadlineSize}
				usesSectionComponent={usesSectionComponent}
			/>
		);
	}

	if (!show) {
		return (
			<div></div>
		);
	}

	const getFederalWithholdingSection = () => {
		if (renderFederal) {
			if (fedPerms > 0 && federal2020 && showFederal2020) {
				return ifFeature('encore',
					(
						<FedWithholding2020
							headerHeadlineSize={ headerHeadlineSize }
							isEmployerView={isEmployerView}
							showExemptions={ isPayrollAdminUser }
							showWithholding={ showFederalWithholding }
							usesSectionComponent={ usesSectionComponent }
							viewOnly={ (fedPerms === 1) || isNhpPreview }
							{ ...federal2020 }
						/>
					),
					(
						<div className={ federalClasses }>
							<FedWithholding2020
								headerHeadlineSize={ headerHeadlineSize }
								isEmployerView={isEmployerView}
								showExemptions={ isPayrollAdminUser }
								showWithholding={ showFederalWithholding }
								usesSectionComponent={ false }
								viewOnly={ (fedPerms === 1) || isNhpPreview }
								{ ...federal2020 }
							/>
						</div>
					)
				)
			}

			if (fedPerms > 0 && federal && !showFederal2020) {
				return ifFeature('encore',
					(
						<FedWithholding
							editPermission={ fedPerms === 3 }
							handle2020ToggleClick={ () => setTemporary2020(true) }
							headerHeadlineSize={ headerHeadlineSize }
							isAdminUser={ isAdminUser }
							isPayrollAdminUser={ isPayrollAdminUser }
							newHirePacket={ newHirePacket }
							show2020Message={ is2020Release }
							usesSectionComponent={ usesSectionComponent }
							viewOnly={ (fedPerms === 1) || is2020Release }
							{ ...federal }
						/>
					),
					(
						<div className={ federalClasses }>
							<FedWithholding
								editPermission={ fedPerms === 3 }
								handle2020ToggleClick={ () => setTemporary2020(true) }
								headerHeadlineSize={ headerHeadlineSize }
								isAdminUser={ isAdminUser }
								isPayrollAdminUser={ isPayrollAdminUser }
								newHirePacket={ newHirePacket }
								show2020Message={ is2020Release }
								usesSectionComponent={ false }
								viewOnly={ (fedPerms === 1) || is2020Release }
								{ ...federal }
							/>
						</div>
					)
				)
			}
		}
		return null
	}

	const getStateWithholdingSection = () => {
		/*Checks if Access Level permission is on the page*/
		if (stateAccessLevel !== undefined) {
			if (stateEditAccessLevel || stateViewOnlyAccessLevel) {
				if (renderState && state) {
					return !newIowaStateForm ? <StateWithholding
						accessLevel={ stateAccessLevel }
						editPermission={ stateEditAccessLevel }
						handleFormMigrationClick={ () => setNewIowaStateForm(true) }
						headerHeadlineSize={ headerHeadlineSize }
						isAdminUser={ isAdminUser }
						isPayrollAdminUser={ isPayrollAdminUser }
						newHirePacket={ newHirePacket }
						onLocationChange={ onStateLocationChange }
						sectionNote={ stateSectionNote }
						selectedLocation={ selectedStateLocation }
						setSelectedStateLocation={ setSelectedStateLocation }
						setShowIowaStateMessage={ setShowIowaStateMessage }
						shouldMigrateToNewForm={ showIowaStateMessage && isNewIowaFormAccessEnabled }
						showLocationRequiredAsterisk={ showStateLocationRequiredAsterisk }
						showWithholding={ showStateWithholding }
						usesSectionComponent={ usesSectionComponent }
						viewOnly={ stateViewOnlyAccessLevel || isNhpPreview }
						{ ...state }
					/> : <IowaStateWithholding
						accessLevel={ stateAccessLevel }
						editPermission={ stateEditAccessLevel }
						headerHeadlineSize={ headerHeadlineSize }
						isAdminUser={ isAdminUser }
						isPayrollAdminUser={ isPayrollAdminUser }
						newHirePacket={ newHirePacket }
						onLocationChange={ onStateLocationChange }
						sectionNote={ stateSectionNote }
						selectedLocation={ selectedStateLocation }
						setNewIowaStateForm={ setNewIowaStateForm }
						setSelectedStateLocation={ setSelectedStateLocation }
						showLocationRequiredAsterisk={ showStateLocationRequiredAsterisk }
						showWithholding={ showStateWithholding }
						usesSectionComponent={ usesSectionComponent }
						viewOnly={ stateViewOnlyAccessLevel || isNhpPreview }
						{ ...state }
					/>
				}
			}
		} else if (renderState && statePerms > 0 && state) {
			return !newIowaStateForm ? <StateWithholding
				editPermission={ statePerms === 3 }
				handleFormMigrationClick={ () => setNewIowaStateForm(true) }
				headerHeadlineSize={ headerHeadlineSize }
				isAdminUser={ isAdminUser }
				isPayrollAdminUser={ isPayrollAdminUser }
				newHirePacket={ newHirePacket }
				onLocationChange={ onStateLocationChange }
				sectionNote={ stateSectionNote }
				selectedLocation={ selectedStateLocation }
				setSelectedStateLocation={ setSelectedStateLocation }
				setShowIowaStateMessage={ setShowIowaStateMessage }
				shouldMigrateToNewForm={ showIowaStateMessage }
				showLocationRequiredAsterisk={ showStateLocationRequiredAsterisk }
				showWithholding={ showStateWithholding }
				usesSectionComponent={ usesSectionComponent }
				viewOnly={ (statePerms === 1) || isNhpPreview }
				{ ...state }
			/> : <IowaStateWithholding
				editPermission={ statePerms === 3 }
				headerHeadlineSize={ headerHeadlineSize }
				isAdminUser={ isAdminUser }
				isPayrollAdminUser={ isPayrollAdminUser }
				newHirePacket={ newHirePacket }
				onLocationChange={ onStateLocationChange }
				sectionNote={ stateSectionNote }
				selectedLocation={ selectedStateLocation }
				setNewIowaStateForm={ setNewIowaStateForm }
				setSelectedStateLocation={ setSelectedStateLocation }
				showLocationRequiredAsterisk={ showStateLocationRequiredAsterisk }
				showWithholding={ showStateWithholding }
				usesSectionComponent={ usesSectionComponent }
				viewOnly={ (statePerms === 1) || isNhpPreview }
				{ ...state }
			/>

		}
		// None of the above cases applied
		return null;
	}

	const getUnemploymentSection = () => {
		/*Checks if Access Level permission is on the page*/
		if (unemploymentAccessLevel !== undefined) {
			if ((unemploymentEditAccessLevel || unemploymentViewOnlyAccessLevel)) {
				return <StateUnemployment
					accessLevel={ unemploymentAccessLevel }
					editPermission={ unemploymentEditAccessLevel }
					headerHeadlineSize={ headerHeadlineSize }
					isAdminUser={ isAdminUser }
					isPayrollAdminUser={ isPayrollAdminUser }
					onLocationChange={ onStateUnemploymentLocationChange }
					sectionNote={ stateUnemploymentSectionNote }
					showLocationRequiredAsterisk={ showStateUnemploymentLocationRequiredAsterisk }
					showUnemployment={ showStateUi }
					usesSectionComponent={ usesSectionComponent }
					viewOnly={ unemploymentViewOnlyAccessLevel }
					{ ...stateUnemployment }
				/>
			}
		} else if (renderStateUnemployment && (isAdminUser || isPayrollAdminUser || canAccessStateUiFields) && stateUnemployment) {
			return <StateUnemployment
				headerHeadlineSize={ headerHeadlineSize }
				isAdminUser={ isAdminUser }
				isPayrollAdminUser={ isPayrollAdminUser }
				onLocationChange={ onStateUnemploymentLocationChange }
				sectionNote={ stateUnemploymentSectionNote }
				showLocationRequiredAsterisk={ showStateUnemploymentLocationRequiredAsterisk }
				showUnemployment={ showStateUi }
				usesSectionComponent={ usesSectionComponent }
				viewOnly={ statePerms === 1 }
				{ ...stateUnemployment }
			/>
		}
		
		return null;
	}

	function renderWithholding() {
		return (
			<>
				{ getFederalWithholdingSection() }
				{ getStateWithholdingSection() }
				{ getUnemploymentSection() }
			</>
		);
	}

	return ifFeature('encore', (
		<Flex flexDirection="column" gap={ sectionsFlexGap }>
			{ renderWithholding() }
		</Flex>
	), renderWithholding());
}
