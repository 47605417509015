import {
	canUseLocalStorage,
} from '@utils/localstorage';
import {
		MFA_JWT_REMEMBER_TOKEN_VALIDITY,
		MFA_JWT_REMEMBER_TOKEN_VALIDITY_MAX_RETRIES,
		MFA_JWT_REMEMBER_TOKEN_VALIDITY_RETRY_TIMEOUTS
} from './constants';

import {
	validateRememberToken,
} from './service';

export const REMEMBER_TOKEN_KEY = 'remember_token';

export function getStoredRememberToken(): string {
	if (canUseLocalStorage()) {
		const token = localStorage.getItem(REMEMBER_TOKEN_KEY);
		return token;
	}
	return null;
}

let validateRememberTokenTries = 0;

const delayPromise = (ms)=>new Promise(resolve=>setTimeout(resolve, ms));

export async function isRememberTokenValid(userId: number, rememberToken: string): Promise<MFA_JWT_REMEMBER_TOKEN_VALIDITY> {
	if (!rememberToken) {
		return MFA_JWT_REMEMBER_TOKEN_VALIDITY.INVALID;
	}

	try {
		validateRememberTokenTries++;
		const {
			status,
		} = await validateRememberToken(userId, rememberToken);

		if (status === 200) {
			validateRememberTokenTries = 0;
			return MFA_JWT_REMEMBER_TOKEN_VALIDITY.VALID;
		}

		return handleUnexpectedError();
	} catch (error) {
		if (error?.response?.status === 401 && error?.response?.data?.errorCode === 'Invalid JWT') {
			return MFA_JWT_REMEMBER_TOKEN_VALIDITY.INVALID;
		}

		return handleUnexpectedError();
	}

	function handleUnexpectedError() {
		if (validateRememberTokenTries <= MFA_JWT_REMEMBER_TOKEN_VALIDITY_MAX_RETRIES) {
			const delay = MFA_JWT_REMEMBER_TOKEN_VALIDITY_RETRY_TIMEOUTS[validateRememberTokenTries];
			console.log(`Error validating remember token, retrying in ${delay/1000} seconds...`);
			return delayPromise(delay).then(() => isRememberTokenValid(userId, rememberToken));
		}
		validateRememberTokenTries = 0;
		return MFA_JWT_REMEMBER_TOKEN_VALIDITY.ERROR;
	}
}


export function removeStoredRememberToken(): void {
	if (canUseLocalStorage()) {
		localStorage.removeItem(REMEMBER_TOKEN_KEY);
	}
}

export function setStoredRememberToken(value: string): void {
	if (canUseLocalStorage()) {
		localStorage.setItem(REMEMBER_TOKEN_KEY, value);
	}
}
