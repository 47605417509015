import { FC, ReactElement, Children, isValidElement, useState, cloneElement } from 'react';
import { Panel } from './components';
import { PanelProps, RuntimePanelProps } from './types';

interface CollapseType<T> extends FC<T> {
	Panel: FC<React.PropsWithChildren<PanelProps>>
}

interface Props{
	children: ReactElement<RuntimePanelProps> | ReactElement<RuntimePanelProps>[]
	accordian?: boolean
}

export const Collapse: CollapseType<Props> = (props) => {
	const { children, accordian } = props;
	const [openKeys, setOpenKeys] = useState([]);

	const handlePanelChange = (key: string): void => {
		if (accordian) {
			if (openKeys.includes(key)) {
				setOpenKeys([]);
			} else {
				setOpenKeys([key]);
			}
		} else {
			const index = openKeys.findIndex(e => e === key);
			if (index === -1) {
				setOpenKeys((prev: string[]) => [...prev, key]);
			} else {
				setOpenKeys((prev: string[]) => {
					const arr = [...prev];
					arr.splice(index, 1);
					return arr;
				});
			}
		}
	};


	return (
		<>
			{ Children.map(children, (child) => {
				if (isValidElement(child)) {
					return cloneElement<RuntimePanelProps>(child, {openKeys, handlePanelChange});
				}
				return child;

			}) }
		</>
	);
};

Collapse.Panel = Panel;
