/* eslint-disable react/sort-comp */
import {
	BodyText,
	Flex,
	IconV2,
	Select,
} from '@bamboohr/fabric';
/* @startCleanup encore */
import { InfoCircle15x15 } from '@bamboohr/grim';
import { ifFeature } from '@utils/feature';
/* @endCleanup encore */
import { isEnabled } from 'FeatureToggle.util';
import { Component, Fragment, createRef } from 'react';
/* @startCleanup encore */
import OldSelect from 'select.react';
/* @endCleanup encore */

import './state-withholding.styl';
import { AdditionalWithholding } from '../additional-withholding.react';
import { HelpPopover } from '../help-popover.react';
import { IowaStateWithholdingMessage } from '../iowa-state-withholding-message.react';
import { LinkedSelects } from '../linked-selects.react';
import { OptionalTextInput } from '../optional-text-input.react';
import { OptionalCheckbox } from '../optional-checkbox.react';
import { OptionalSelect } from '../optional-select.react';
import { OptionalUsdInput } from '../optional-usd-input/optional-usd-input';
import { SectionStyledBox } from '../section-styled-box';
import { SectionStyledHeader } from '../section-styled-header';
import { numberWithinRange } from '../../utils';
import { showStateTaxWHMigrationMessage } from '../withholding.react/withholding.domain';

const feAccessLevelsQuickForms = isEnabled('PAY_INFO_TAB_ACCESS_LEVELS');

import c from 'classnames';

const OPTIONAL_FIELDS_MAP = {
	text: OptionalTextInput,
	checkbox: OptionalCheckbox,
	select: OptionalSelect,
	linkedSelects: LinkedSelects,
	usd: OptionalUsdInput,
};

export class StateWithholding extends Component {
	constructor(props) {
		super(props);
		this._helpPopoverRef = createRef();
		this._locationSelectRef = createRef();
		this._statusSelectRef = createRef();
		let filingStatus = null;
		let exemptions = 0;
		let ignore = false;
		let stateAbbreviation = null;
		const selectedLocation = props.selectedLocation === undefined || props.selectedLocation === null ? props.locations.selected : props.selectedLocation;
		const activeLocation = selectedLocation ? props.locations.byId[selectedLocation] : null;

		if (activeLocation) {
			const selectedFilingStatus = activeLocation.fields.filingStatus ? activeLocation.fields.filingStatus.find(status => status.selected === true) : null;
			filingStatus = selectedFilingStatus ? selectedFilingStatus.val : null;
			exemptions = activeLocation.fields.exemptions ? activeLocation.fields.exemptions.amount : 0;
			ignore = activeLocation.fields.ignoreStatusExemptions ? activeLocation.fields.ignoreStatusExemptions.checked : false;
			stateAbbreviation = activeLocation.state;
		}

		// eslint-disable-next-line react/state-in-constructor
		this.state = {
			shouldMigrateToNewForm: props.shouldMigrateToNewForm,
			selectedLocation,
			filingStatus,
			exemptions,
			ignore,
			stateAbbreviation,
			showHelpPopover: false,
		};
	}

	render() {
		const { selectedLocation, shouldMigrateToNewForm } = this.state;
		const {
			accessLevel,
			editPermission,
			handleFormMigrationClick,
			headerHeadlineSize,
			isAdminUser,
			isPayrollAdminUser,
			locations,
			newHirePacket,
			sectionNote,
			showWithholding,
			usesSectionComponent,
			viewOnly,
		} = this.props;
		const activeLocation = locations.byId[selectedLocation];

		let optionalFields = null;
		if (activeLocation && activeLocation.fields.optionalFields) {

			// Tags the first optional fields checkbox as the first checkbox so that
			optionalFields = activeLocation.fields.optionalFields.allIds.reduce((hasCheckbox, id) => {
				const field = activeLocation.fields.optionalFields.byId[id];
				if (field.type === 'checkbox') {
					field.data.firstCheckbox = !hasCheckbox;
					hasCheckbox = true;
				}
				return hasCheckbox;
			}, false);

			// dynamically configure and render the optional fields as form components
			optionalFields = activeLocation.fields.optionalFields.allIds.map((id) => {
				const field = activeLocation.fields.optionalFields.byId[id];
				const { label } = field.data;
				
				// map the field type to the appropriate component
				const FieldComponent = OPTIONAL_FIELDS_MAP[field.type];

				// NOTE: If location is Iowa, we want to hide these particular optional fields from this view.
				if (activeLocation.state === 'IA' &&
					(label === 'Married Filing Jointly with Both Spouses Working' ||
						label === 'Total Allowances')) {
					return null;
				}

				return (
					<FieldComponent
						data={ field.data }
						help={ field.help }
						id={ id }
						key={ id }
						viewOnly={ viewOnly || shouldMigrateToNewForm }
					/>
				);
			});
		}

		const fieldSetClasses = c(
			{ 'fab-FormSection StateTax': true },
			{ 'fab-FormSection--hidden': showWithholding === false },
		);

		const legendClasses = 'fab-FormSection__legend';

		return ifFeature('encore',
			(
				<SectionStyledBox
					hidden={ showWithholding === false }
					usesSectionComponent={ usesSectionComponent }
				>
					<fieldset>
						<SectionStyledHeader
							hasPopover={ true }
							headlineSize={ headerHeadlineSize }
							popoverContent={ $.__('This determines how much you will withhold from your paycheck for state taxes.') }
							popoverIcon="circle-question-regular"
							popoverPlacement="bottom"
							popoverTitle={ $.__('State Tax Withholding') }
						>
							{ $.__('State Tax Withholding') }
						</SectionStyledHeader>
						{ sectionNote ? (
							<Flex
								alignItems="center"
								gap={ 0.5 }
							>
								<IconV2
									color="info-medium"
									name="circle-info-solid"
									size={ 16 }
								/>
								<BodyText
									color="info-medium"
									size='small'
									weight="semibold"
								>
									{ sectionNote }
								</BodyText>
							</Flex>
						) : null }

						{ locations && this._renderLocationsSelect() }

						{ shouldMigrateToNewForm && <IowaStateWithholdingMessage handleToggleClick={ handleFormMigrationClick } /> }

						{ activeLocation && (
							<Fragment>
								<div className="fieldRow">
									{ activeLocation.fields.filingStatus && this._renderFilingStatusSelect(activeLocation.fields.filingStatus) }
									{ activeLocation.fields.exemptions && this._renderExemptionsInput(activeLocation.fields.exemptions) }
									{ feAccessLevelsQuickForms && accessLevel !== undefined ? (
										<>
											{ ((editPermission || viewOnly || shouldMigrateToNewForm) && activeLocation.fields.ignoreStatusExemptions) && this._renderIgnoreCheckbox(activeLocation.fields) }
										</>
									) : (
										<>
											{ ((isAdminUser || isPayrollAdminUser || editPermission) && activeLocation.fields.ignoreStatusExemptions) && this._renderIgnoreCheckbox(activeLocation.fields) }
										</>
									) }
								</div>
								{ activeLocation.fields.additionalWithholding && <AdditionalWithholding data={ activeLocation.fields.additionalWithholding } type="state" viewOnly={ viewOnly || shouldMigrateToNewForm } /> }
								{ optionalFields }
							</Fragment>
						) }
					</fieldset>
				</SectionStyledBox>
			),
			(
				<fieldset className={ fieldSetClasses }>
					<legend className={ legendClasses }>
						{ $.__('State Tax Withholding') }
						<HelpPopover
							addTop={ newHirePacket }
							content={ $.__('This determines how much you will withhold from your paycheck for state taxes.') }
							title={ $.__('State Tax Withholding') }
						/>
					</legend>
					{ sectionNote && (
						<div className='StateWithholding__sectionNoteContainer'>
							<span className='StateWithholding__sectionNoteIcon'><InfoCircle15x15 /></span>
							<p className='StateWithholding__sectionNoteText'>
								{ sectionNote }
							</p>
						</div>
					) }

					{ locations && this._renderLocationsSelect() }

					{ shouldMigrateToNewForm && <IowaStateWithholdingMessage handleToggleClick={ handleFormMigrationClick } /> }

					{ activeLocation && (
						<Fragment>
							<div className="fieldRow">
								{ activeLocation.fields.filingStatus && this._renderFilingStatusSelect(activeLocation.fields.filingStatus) }
								{ activeLocation.fields.exemptions && this._renderExemptionsInput(activeLocation.fields.exemptions) }
								{ feAccessLevelsQuickForms && accessLevel !== undefined ? (
									<>
										{ ((editPermission || viewOnly || shouldMigrateToNewForm) && activeLocation.fields.ignoreStatusExemptions) && this._renderIgnoreCheckbox(activeLocation.fields) }
									</>
								) : (
									<>
										{ ((isAdminUser || isPayrollAdminUser || editPermission) && activeLocation.fields.ignoreStatusExemptions) && this._renderIgnoreCheckbox(activeLocation.fields) }
									</>
								) }
							</div>
							{ activeLocation.fields.additionalWithholding && <AdditionalWithholding data={ activeLocation.fields.additionalWithholding } type="state" viewOnly={ viewOnly || shouldMigrateToNewForm } /> }
							{ optionalFields }
						</Fragment>
					) }
				</fieldset>
			)
		);
	}

	/* @startCleanup encore */
	/**
	 * Get data object to be passed to location Select component
	 * @return {object}
	 */
	_getLegacyLocationSelectData = () => {
		const {
			locations,
			newHirePacket,
			viewOnly,
		} = this.props;
		const options = locations.allIds.map((location) => {
			const option = locations.byId[location];
			option.value = option.val;
			option.selected = location === this.state.selectedLocation;
			option.id = location;
			return option;
		});

		return {
			data: {
				items: options,
			},
			onChange: this._handleLegacyLocationChange,
			settings: {
				name: 'state[location]',
				disabled: (viewOnly && !this.state.shouldMigrateToNewForm) || newHirePacket,
			},
			className: 'xlong StateWithholding__select js-stateLocationSelect',
			width: 5
		};
	};
	/* @endCleanup encore */

	/**
	 * Get data object to be passed to location Select component
	 * @return {object}
	 */
	_getLocationSelectItems = () => {
		const {
			locations,
		} = this.props;
		const options = locations.allIds.map((location) => {
			const option = locations.byId[location];
			option.id = location;
			option.value = option.val;
			option.text = option.val;
			return option;
		});

		return options;
	};

	/* @startCleanup encore */
	/**
	 * Get data object to be passed to filing status Select component
	 * @return {object}
	 */
	_getLegacyStatusSelectData = (filingStatus) => {
		const {
			filingStatus: selectedFilingStatus,
			ignore,
			shouldMigrateToNewForm,
		} = this.state;
		const { viewOnly } = this.props;

		const options = filingStatus.map((status) => {
			return {
				displayText: ignore ? 'NA' : status.displayText,
				value: status.val,
				selected: status.val === selectedFilingStatus,
			};
		});

		return {
			data: {
				items: options,
			},
			onChange: this._handleLegacyStatusChange,
			settings: {
				name: 'state[status]',
				notClearable: false,
				placeholder: ignore ? 'NA' : '-- Select --',
			},
			disabled: ignore || viewOnly || shouldMigrateToNewForm,
			className: 'xlong StateWithholding__select js-stateStatusSelect',
			width: 5,
		};
	};
	/* @endCleanup encore */

	/**
	 * Get data object to be passed to filing status Select component
	 * @return {object}
	 */
	_getStatusSelectData = (filingStatus) => {
		const {
			ignore,
		} = this.state;

		const options = filingStatus.map((status) => {
			return {
				text: ignore ? 'NA' : status.displayText,
				value: status.val,
			};
		});

		return options;
	};

	// Event handlers
	/* @startCleanup encore */
	_handleLegacyLocationChange = (event) => {
		const { locations, onLocationChange, setSelectedStateLocation } = this.props;

		$('.js-stateLocationFieldBox').removeClass('error');
		this.setState({
			selectedLocation: event ? event.id : null,
			stateAbbreviation: event ? event.state : null,
			shouldMigrateToNewForm: showStateTaxWHMigrationMessage(locations, event.id),
		});
		setSelectedStateLocation(event.id);
		if (onLocationChange) {
			onLocationChange(event);
		}
	};
	/* @endCleanup encore */

	_handleLocationChange = (selectedValues) => {
		const {
			locations,
			onLocationChange,
			setSelectedStateLocation,
		} = this.props;
		if (this._locationSelectRef.current) {
			this._locationSelectRef.current.classList.remove('error');
		}
		const [selectedValue] = selectedValues;
		const selectedLocation = locations.byId[selectedValue];
		this.setState({
			selectedLocation: selectedLocation ? selectedLocation.id : null,
			stateAbbreviation: selectedLocation ? selectedLocation.state : null,
			shouldMigrateToNewForm: showStateTaxWHMigrationMessage(locations, selectedLocation),
		});
		setSelectedStateLocation(selectedValue);
		if (onLocationChange) {
			onLocationChange({
				val: selectedValue,
			});
		}
	};

	/* @startCleanup encore */
	_handleLegacyStatusChange = (event) => {
		$('.js-stateStatusFieldBox').removeClass('error');
		this.setState({
			filingStatus: event ? event.value : '',
		});
	};
	/* @endCleanup encore */

	_handleStatusChange = (selectedValues) => {
		if (this._statusSelectRef.current) {
			this._statusSelectRef.current.classList.remove('error');
		}
		const [selectedValue = ''] = selectedValues;
		this.setState({
			filingStatus: selectedValue,
		});
	};

	_handleExemptionsChange = (event, range) => {
		if (numberWithinRange(event.target.value, range.min, range.max)) {
			this.setState({
				exemptions: event.target.value
			});
		}
	};

	_handleIgnoreChange = event => this.setState({
		ignore: event.target.checked
	});

	_getLabelClass = () => {
		const { viewOnly } = this.props;
		const { shouldMigrateToNewForm } = this.state;

		return c({ 'fab-Label': true }, { 'fab-Label--disabled': viewOnly || shouldMigrateToNewForm });
	};

	// Render different fields
	_renderLocationsSelect = () => {
		const {
			newHirePacket,
			showLocationRequiredAsterisk,
			viewOnly,
		} = this.props;
		const {
			selectedLocation,
			shouldMigrateToNewForm,
			stateAbbreviation,
		} = this.state;

		const noteClass = 'fab-FormNote';

		return ifFeature('encore',
			(
				<Flex alignItems="center" marginTop={ 2 }>
					<div className="fieldRow">
						<div className="fieldBox js-stateLocationFieldBox required">
							<label className={
								c({
									'fab-Label': true,
									'fab-Label--disabled': viewOnly && !shouldMigrateToNewForm,
									// Making the required asterisk opt-in for now as there might be pre-existing usages that wouldn't want it to show?
									'fab-Label--required': !!showLocationRequiredAsterisk
								})
							} htmlFor="stateLocation">{ $.__('Location') }</label>
							<div className="fieldDiv">
								<Select
									id="stateLocation"
									isDisabled={ (viewOnly && !shouldMigrateToNewForm) || newHirePacket }
									items={ this._getLocationSelectItems() }
									name="state[location]"
									notClearable={ true }
									onChange={ this._handleLocationChange }
									selectedValues={ [selectedLocation] }
									width={ 5 }
								/>
							</div>
							{ stateAbbreviation && (
								<span className={ noteClass }>
									{ $.__('State: %1$s', stateAbbreviation) }
								</span>
							) }
						</div>
					</div>
				</Flex>
			),
			(
				<div className="fieldRow">
					<div className="fieldBox js-stateLocationFieldBox required">
						<label className={
							c({
								'fab-Label': true, 'fab-Label--disabled': viewOnly && !shouldMigrateToNewForm,
								// Making the required asterisk opt-in for now as there might be pre-existing usages that wouldn't want it to show?
								'fab-Label--required': !!showLocationRequiredAsterisk
							})
						} htmlFor="stateLocation">{ $.__('Location') }</label>
						<div className="fieldDiv">
							<OldSelect id="stateLocation" { ...this._getLegacyLocationSelectData() } />
						</div>
						{ stateAbbreviation && (
							<span className={ noteClass }>
								{ $.__('State: %1$s', stateAbbreviation) }
							</span>
						) }
					</div>
				</div>
			)
		);
	};

	_renderFilingStatusSelect = (filingStatus) => {
		const {
			viewOnly,
		} = this.props;
		const {
			filingStatus: selectedFilingStatus,
			ignore,
			shouldMigrateToNewForm,
		} = this.state;
		const fieldBoxClasses = c(
			'fieldBox js-stateStatusFieldBox',
			{ 'required': !ignore }
		);

		return (
			<div className={ fieldBoxClasses } ref={ this._statusSelectRef }>
				<label className={
					c(this._getLabelClass(), {
						'fab-Label--required': true
					})
				} htmlFor="stateStatus">{ $.__('Filing Status') }</label>
				<div className="fieldDiv">
					{ ifFeature('encore',
						(
							<Select
								id="stateStatus"
								isDisabled={ ignore || viewOnly || shouldMigrateToNewForm }
								items={ this._getStatusSelectData(filingStatus) }
								name="state[status]"
								notClearable={ false }
								onChange={ this._handleStatusChange }
								placeholder={ ignore ? 'NA' : '-- Select --' }
								selectedValues={ [selectedFilingStatus] }
								width={ 5 }
							/>
						),
						(
							<OldSelect id="stateStatus" { ...this._getLegacyStatusSelectData(filingStatus) } />
						)
					) }
				</div>
			</div>
		);
	};

	_renderExemptionsInput = (exemptions) => {
		const inputClasses = ifFeature('encore', 'fab-TextInput fab-TextInput--width3', 'fab-TextInput fab-TextInput--width1');

		return (
			<div className="fieldBox">
				<label className={ this._getLabelClass() } htmlFor="stateExemptions">{ $.__('Exemptions') }</label>
				<div className="fieldDiv">
					<input
						className={ inputClasses }
						disabled={ this.state.ignore || this.props.viewOnly || this.state.shouldMigrateToNewForm }
						id="stateExemptions"
						name="state[dependents]"
						onChange={ event => this._handleExemptionsChange(event, exemptions.range) }
						type="text"
						value={ this.state.ignore ? 'NA' : this.state.exemptions }
					/>
				</div>
			</div>
		);
	};

	_renderIgnoreCheckbox = (fields) => {
		const labelText = $.__('Ignore filing status/other adjustments');

		return (
			<div className="fieldBox">
				<div className="fieldDiv">
					<div className="fab-Checkbox StateWithholding__exempt">
						<input
							className="fab-Checkbox__input"
							defaultChecked={ this.state.ignore ? 'checked' : null }
							disabled={ this.props.viewOnly || this.state.shouldMigrateToNewForm }
							id="stateIgnore"
							name="state[taxExempt]"
							onChange={ this._handleIgnoreChange }
							type="checkbox"
						/>
						<span className="fab-Checkbox__box"></span>
						<label className="fab-Checkbox__label" htmlFor="stateIgnore">{ labelText }</label>
					</div>
				</div>
			</div>
		);
	};
}
